







import { defineComponent, reactive } from "@vue/composition-api"
import { pages } from "@microsoft/teams-js"
import Loading from "@/components/Loading.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  listIsDeleteing: boolean
}

export default defineComponent({
  components: {
    Loading,
  },
  setup() {
    const state = reactive<State>({
      listIsDeleteing: false,
    })
    const {
      getProjectAsync,
      deleteProjectAsync,
    } = projectsContainer.useContainer()

    const { getContext } = teamsContextContainer.useContainer()
    pages.config.registerOnRemoveHandler(async removeEvent => {
      state.listIsDeleteing = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      if (project) {
        await deleteProjectAsync(project)
      }
      removeEvent.notifySuccess()
      state.listIsDeleteing = false
    })
    pages.config.setValidityState(false)

    return {
      state,
    }
  },
})
